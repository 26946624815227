.er_cases {
  position: relative;
  width: 100%;
  overflow: hidden;

  &__backgrounds {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &__item {
      width: 100%;
      height: 100%;
      opacity: 0;
      visibility: hidden;
      background-size: cover;
      background-position: center;
      position: absolute;
      top: 0;

      &_active {
        opacity: 1;
        visibility: visible;
      }

      &__digitalBridge {
        @include background-image('cases/Cases-digital-bride', true, false);
      }

      &__nis {
        @include background-image('cases/Cases-nis', true, false);
      }

      &__capitalDays {
        @include background-image('cases/Cases-capital-days', true, false);
      }

      &__cavf {
        @include background-image('cases/Cases-cavf', true, false);
      }

      &__mena {
        @include background-image('cases/Cases-mena-business-expo', true, false);
      }

      &__openKZ {
        @include background-image('cases/Cases-open-kz', true, false);
      }

      &__technoprom {
        @include background-image('cases/Cases-techno-prom', true, false);
      }

      &__gastreet {
        @include background-image('cases/Cases-gastreet', true, false);
      }

      &__404 {
        @include background-image('cases/Cases-404', true, false);
      }

      &__techWeek {
        @include background-image('cases/Cases-tech-week', true, false);
      }

      &__hospis {
        @include background-image('cases/Cases-hospis', true, false);
      }

      &__maxConference {
        @include background-image('cases/Cases-MAXConference', true, false);
      }

      &__chiesi {
        @include background-image('cases/Cases-chiesi-pharmaceuticals', true, false);
      }

      &__mercedes {
        @include background-image('cases/Cases-mercedes-benz', true, false);
      }
    }
  }

  &__slider {
    padding: 80px 0 158px;
    position: relative;
    z-index: 10;

    @include respond-to(tablet) {
      padding: 60px 0 118px;
    }

    @include respond-to(phone) {
      padding: 40px 0 80px;
    }

    &_wrapper {
      align-items: center;
    }

    &__item {
      &__container {
        width: 100%;
        max-width: 960px;

        @include respond-to(tablet-large) {
          max-width: 820px;
        }

        @include respond-to(tablet) {
          max-width: 660px;
        }

        @include respond-to(phone) {
          padding: 0 40px;
        }

        @include respond-to(mini) {
          padding: 0 24px;
        }

        margin: 0 auto;
        &__top {
          display: flex;
          //align-items: center;

          @include respond-to(mini) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }

          &__logo {
            flex-shrink: 0;
            img {
              @include respond-to(tablet) {
                width: 100px;
              }

              @include respond-to(phone) {
                width: 80px;
              }
            }
          }

          &__head {
            margin-left: 40px;
            margin-top: 24px;

            @include respond-to(tablet) {
              margin-left: 30px;
              margin-top: 14px;
            }

            @include respond-to(phone) {
              margin-left: 20px;
              margin-top: 12px;
            }

            @include respond-to(mini) {
              margin-left: 0;
              margin-top: 20px;
            }

            &__date {
              font-weight: 700;
              font-size: 20px;
              line-height: 28px;
              letter-spacing: 0.05em;
              text-transform: uppercase;
              color: $white-80;
              margin-bottom: 16px;

              @include respond-to(tablet) {
                margin-bottom: 10px;
              }

              @include respond-to(phone) {
                font-size: 18px;
                line-height: 24px;
                margin-bottom: 8px;
              }

              @include respond-to(mini) {
                text-align: center;
              }
            }

            &__title {
              font-weight: 800;
              font-size: 44px;
              line-height: 52px;
              color: $white-100;

              @include respond-to(tablet) {
                font-size: 32px;
                line-height: 38px;
              }

              @include respond-to(phone) {
                font-size: 24px;
                line-height: 28px;
              }

              @include respond-to(mini) {
                text-align: center;
              }
            }
          }
        }

        &__block {
          margin-top: 100px;
          background: $white-10;
          backdrop-filter: blur(20px);
          border-radius: 30px;
          padding: 48px 60px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          @include respond-to(tablet) {
            margin-top: 80px;
            padding: 32px 40px;
          }

          @include respond-to(phone) {
            margin-top: 40px;
            padding: 24px 30px;
          }

          &__text {
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            color: $white-100;
            margin-bottom: 32px;

            @include respond-to(tablet) {
              font-size: 16px;
              line-height: 22px;
              margin-bottom: 20px;
            }

            @include respond-to(phone) {
              font-size: 14px;
              line-height: 20px;
              margin-bottom: 16px;
            }
          }

          &__list {
            list-style-type: none;

            li {
              display: flex;
              font-weight: 500;
              font-size: 18px;
              line-height: 24px;
              color: $white-100;

              @include respond-to(tablet) {
                font-size: 16px;
                line-height: 22px;
              }

              @include respond-to(phone) {
                font-size: 14px;
                line-height: 20px;
              }

              &::before {
                content: '—';
                display: inline-flex;
                margin-right: 16px;

                @include respond-to(phone) {
                  margin-right: 14px;
                }
              }
            }
          }

          &__link {
            @include main-button;
            margin-top: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 10;

            @include respond-to(tablet) {
              margin-top: 30px;
            }

            @include respond-to(phone) {
              margin-top: 20px;
            }

            @include respond-to(mini) {
              margin-left: auto;
              margin-right: auto;
            }

            svg {
              margin-left: 8px;
            }
          }
        }
      }
    }
  }

  &__navigation {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;

    &__item {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 44px;
      height: 44px;
      z-index: 11;
      cursor: pointer;
      color: $pagination;

      @include respond-to(phone) {
        width: 36px;
        height: 36px;
      }

      svg {
        transition: fill-opacity 0.25s linear;

        @include respond-to(phone) {
          width: 36px;
          height: 36px;
        }

        &:hover {
          fill-opacity: 0.6;
        }
      }

      &.swiper-button-disabled {
        svg {
          fill-opacity: 0.3;
          cursor: default;
        }
      }

      &_prev {
        left: 136px;

        @include respond-to(desktop-small) {
          left: 80px;
        }

        @include respond-to(tablet-large) {
          left: 40px;
        }

        @include respond-to(tablet) {
          left: 10px;
        }

        @include respond-to(phone) {
          left: 4px;
        }
      }

      &_next {
        right: 136px;

        @include respond-to(desktop-small) {
          right: 80px;
        }

        @include respond-to(tablet-large) {
          right: 40px;
        }

        @include respond-to(tablet) {
          right: 10px;
        }

        @include respond-to(phone) {
          right: 4px;
        }
      }
    }
  }

  &__ticker{
    position: absolute;
    align-self: self-end;
    display: flex;
    align-items: center;
    background: transparent;
    overflow: hidden;
    z-index: 2;
    width: 100%;
    height: 100%;

    @include respond-to(tablet-large){
      display: none;
    }

    &_left{
      transform: rotate(-90deg);
      left: calc(-50% + 80px);
    }

    &_right{
      transform: rotate(-90deg);
      right: calc(-50% + 80px);
    }

    &__inner{
      font-size: 74px;
      font-weight: 900;
      letter-spacing: 9px;
      line-height: 104px;
      padding-bottom: 16px;
      color: $white-30;
      white-space: nowrap;
      text-transform: uppercase;

      &::after {
        content: ' • кейстер • кейстер • кейстер • кейстер • кейстер • кейстер';
      }

      &_left{
        animation: ticker $length-of-ticker-animation infinite linear;
      }

      &_right{
        animation: ticker $length-of-ticker-animation infinite linear;
        animation-direction: reverse;
      }

    }
  }
}
